import React from 'react'
import { Form } from "../../plugins/gatsby-plugin-atollon";
import { Loading, Upload, File } from './icons';
import Select from 'react-select'

const FormComponent = (props) => {

  const staticValues = {}

  if (props.jobTitle) {
    staticValues['job-title'] = props.jobTitle
  }
  if (props.title) {
    staticValues['venue'] = props.title
  }

  return (
    <Form
      schema={props.form}
      onPreSubmit={props.onPreSubmit}
      onSubmitSuccess={props.onSubmitSuccess}
      values={staticValues}
      renderField={(field) => {
        if (field.type === "text" || field.type === "email" || field.type === "tel") {
          let defaultValue = field.label === 'Product' ? props.title : undefined
          if (field.label === 'Product') defaultValue = props.title
          let defaultType = (field.label === 'Product') ? 'hidden' : field.type
          return (
            <div className={`form__text${field.label === 'Product' ? ' form__hidden' : ''} form__text--${field.name}`}>
              <label htmlFor={field.id}>
                {field.label}
              </label>
              <input
                ref={field.ref}
                onBlur={field.onBlur}
                onChange={field.onChange}
                name={field.name}
                type={defaultType}
                defaultValue={defaultValue}
                value={defaultValue}
                id={field.id}
                maxLength={field.label === 'Postcode' ? 6 : undefined}
                autoComplete={field.autoComplete}
                placeholder={defaultValue ? defaultValue : field.placeholder}
                aria-required={field.required}
                disabled={field.label === 'Product' ? true : field.disabled}
                aria-errormessage={field.hasErrors ? field.errors.join(", ") : undefined}
                aria-invalid={field?.invalid}
              />
              {field.hasErrors && (
                <div role="alert">{field.errors.join(", ")}</div>
              )}
            </div>
          )
        }
        if (field.type === 'file') {
          return (
            <div className='form__dropzone dropzone'>
              <label htmlFor={field.id}>
                {field.label}
              </label>
              <div className='dropzone__button'>
                <div className='btn'>
                  <div className='btn-icons'>
                    <div className='file'><File/></div>
                    <div className='upload'><Upload color='#ffffff' /></div>
                  </div>
                  <input
                      ref={field.ref}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      onClick={field.onClick}
                      accept={field.accept}
                      multiple={field.multiple}
                      onDrop={field.onDrop}
                      name={field.name}
                      type={field.type}
                      id={field.id}
                      placeholder={field.placeholder}
                      aria-required={field.required}
                      disabled={field.disabled}
                      aria-errormessage={field.hasErrors ? field.errors.join(", ") : undefined}
                      aria-invalid={field?.invalid}
                  />
                    {field.isUploadingFile && "Uploading..."}
                    {field.fileUploadSuccessful && "Uploaded"}
                    {(!field.isUploadingFile && !field.fileUploadSuccessful) && <div className='btn-promt'>Drag and drop or <span>Choose File</span></div>}
                </div>
              </div>
              {field.files ?
                <div className="dropzone__content">
                  {field.files.map((file) => (
                    <p key={file.name}>{file.name}</p>
                  ))}
                </div>
                :
                <div className='dropzone__content'>
                  {field.hasErrors ? (
                    <>
                      <p><strong>There was an issue uploading your file.</strong></p>
                      <div role="alert">{field.errors.join(", ")}</div>
                    </>
                  ) : (
                    <>
                      <p>Supported files: {field.options.map(x => x.label).join(", ")}</p>
                      <p>Maximum File Size: 10MB</p>
                    </>
                  )}
                </div>
              }
            </div>
          )
        }
        if (field.type === "textarea") {
          return (
            <div className='form__textarea'>
              <label htmlFor={field.id}>
                  {field.label}
              </label>
              <textarea
                ref={field.ref}
                onBlur={field.onBlur}
                onChange={field.onChange}
                name={field.name}
                id={field.id}
                placeholder={field.placeholder}
                aria-required={field.required}
                disabled={field.disabled}
                aria-errormessage={field.hasErrors ? field.errors.join(", ") : undefined}
                aria-invalid={field?.invalid}
              />
              {field.hasErrors && (
                <div role="alert">{field.errors.join(", ")}</div>
              )}
            </div>
          )
        }
        if (field.type === "select") {
          let options = []
          field.options.map((option) => (
            options.push({ value: option.value, label: option.label })
          ))
          const handleTypeSelect = (e) => {
            let event = {
              target: {
                name: field.name,
                value: e.value
              }
            }
            field.onChange(event)
          };
          return (
            <>
              {field.name === 'beds' && <p className='form__p'>I’m interested in properties that have</p> }
              <div className={`form__select form__select--${field.name}`}>
                <label htmlFor={field.id}>
                  {field.label}
                </label>
                <Select
                  classNamePrefix='react-select'
                  ref={field.ref}
                  onBlur={field.onBlur}
                  onChange={handleTypeSelect}
                  id={field.id}
                  name={field.name}
                  aria-required={field.required}
                  disabled={field.disabled}
                  aria-errormessage={field.hasErrors ? field.errors.join(", ") : undefined}
                  aria-invalid={field?.invalid}
                  options={options}
                  placeholder={field.placeholder}
                />
                {field.hasErrors && (
                  <div role="alert">{field.errors.join(", ")}</div>
                )}
              </div>
            </>
          )
        }
        if (field.type === "radio") {
          return (
            <div className='form__radio'>
              <div>
                {field.label}
              </div>
              {field.options.map((option) => (
                <div className='form__radio-wrapper'>
                  <input
                    id={option.id}
                    type="radio"
                    value={option.value}
                    ref={field.ref}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    name={field.name}
                    aria-required={field.required}
                    disabled={field.disabled}
                    aria-errormessage={field.hasErrors ? field.errors.join(", ") : undefined}
                    aria-invalid={field?.invalid}
                  />
                  <label htmlFor={option.id}>{option.label}</label>
                </div>
              ))}
              {field.hasErrors && (
                <div role="alert">{field.errors.join(", ")}</div>
              )}
            </div>
          )
        }

        if (field.type === "checkbox") {
          return (
            <div className={`form__checkbox${field.label === 'Property Management' ? ' form__checkbox--last' : ''}${field.label === 'Request Section 32' ? ' form__checkbox--hide' : ''}`}>
              <input
                type="checkbox"
                ref={field.ref}
                onBlur={field.onBlur}
                onChange={field.onChange}
                name={field.name}
                id={field.id}
                aria-required={field.required}
                disabled={field.disabled}
                aria-errormessage={field.hasErrors ? field.errors.join(", ") : undefined}
                aria-invalid={field?.invalid}
                defaultChecked={field.label === 'Request Section 32' && props.sectionThirtyTwo ? true : undefined}
              />
              <label htmlFor={field.id}>
                {field.label}
              </label>
              {field.hasErrors && (
                <div role="alert">{field.errors.join(", ")}</div>
              )}
            </div>
          )
        }

          if (field.type === "checkboxes") {
              return (
                  <div className='form__checkboxes'>
                      <label className='form__checkboxes-label'>
                        {field.label}
                      </label>
                      {field.options.map((option) => (
                          <div className="form__checkbox">
                              <input
                                  type="checkbox"
                                  ref={field.ref}
                                  onBlur={field.onBlur}
                                  onChange={field.onChange}
                                  name={field.name}
                                  id={option.id}
                                  aria-required={field.required}
                                  disabled={field.disabled}
                                  aria-errormessage={field.hasErrors ? field.errors.join(", ") : undefined}
                                  aria-invalid={field?.invalid}
                                  value={option.value}
                              />
                              <label htmlFor={option.id}>
                                  {option.label}
                              </label>
                          </div>
                      ))}
                      {field.hasErrors && (
                          <div role="alert">{field.errors.join(", ")}</div>
                      )}
                  </div>
              )
          }
        return (
          <div>field</div>
        )
      }}
      renderApiErrors={({ errors }) => (
        <div role="alert" className="api-error">{errors.join(", ")}</div>
      )}
      renderSubmitButton={({ children }) => (
        <div className='form__submit'><button className='btn' type="submit">{children}</button></div>
      )}
      renderConfirmationMessage={({ message }) => (
        <div className='form__confirmation'>{message}</div>
      )}
      renderLoadingSpinner={() => <div className='form__loading'><Loading /></div>}
    />
  )
}

export default FormComponent
